import React, { FunctionComponent } from 'react';
import { Event, Layout } from '../components';

const EventPage: FunctionComponent = () => (
  <Layout>
    <Event />
  </Layout>
);

export default EventPage;
